import { Box } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { SxProps } from '@mui/system'
import React from 'react'
import { LogoIcon } from '~/shared/ui/icons'

type Props = {
  logoWidth?: number
  color?: string
  sx?: SxProps<Theme>
}

export function Logo({ logoWidth = 160, sx, color }: Props) {
  return (
    <Box sx={sx}>
      <Box maxWidth={logoWidth}>
        <LogoIcon color={color} />
      </Box>
    </Box>
  )
}
